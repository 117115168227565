import { Routes } from '@angular/router';
import { getCaseData, getIncidents, getIncidentsCases, getOfferPackages } from "./resolvers";

export const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/auth.module')
    },
    {
        path: '',
        loadComponent: () => import('./layout/layout.component'),
        children: [
            {
                path: 'requests',
                resolve: {
                    initCaseIncidentResponse: getIncidentsCases
                },
                loadComponent: () => import('./pages/requests/requests.component')
            },
            {
                path: 'case/:incidentId',
                resolve: {
                    data: getCaseData
                },
                loadComponent: () => import('./pages/case/case.component')
            },
            {
                path: 'packages',
                resolve: {
                    offerPackages: getOfferPackages({ isEssential: true })
                },
                loadComponent: () => import('./pages/packages/packages.component'),
            },
            {
                path: '404',
                loadComponent: () => import('./partials/pages/not-found/not-found.component')
            },
            {
                path: 'something-went-wrong',
                loadComponent: () => import('./partials/pages/something-went-wrong/something-went-wrong.component')
            },
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('./partials/pages/welcome/welcome.component')
            }
        ]
    },
    {
        path: '**',
        redirectTo: '404'
    }
];
