import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { ApiService } from "./api/services/api.service";
import { inject } from "@angular/core";
import { combineLatest, map, of, switchMap } from "rxjs";
import { Incident } from "./api/models/api.model";
import { CaseData } from "./resolvers.interfaces";

const handlerResolverError = (router: Router) => async (error: any) => {
    await router.navigateByUrl('/something-went-wrong');
    throw error;
};

export const getIncidents = () => {
    const apiService: ApiService = inject(ApiService);
    return apiService.getIncidents();
}

export const getCaseData = (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
    const apiService: ApiService = inject(ApiService);
    const { incidentId } = activatedRouteSnapshot.params;
    return apiService.getIncidentById(incidentId).pipe(switchMap((incident: Incident) => {
        return combineLatest([
            of(incident),
            apiService.getUserById(incident.userId),
            apiService.getEvidence(incidentId),
            apiService.getTransactions(incidentId)
        ]).pipe(map(([incident, user, evidenceInfo, transactions]) => (<CaseData>{
            incident,
            user,
            evidenceInfo,
            transactions
        })));
    }));
}

export const getIncidentsCases = () => {
    const apiService: ApiService = inject(ApiService);
    return apiService.getIncidentCases();
}

export const getOfferPackages = (queryParams: { isEssential: boolean }) => () => {
    const apiService: ApiService = inject(ApiService);
    return apiService.getOfferPackages(queryParams);
}
