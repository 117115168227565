import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { catchError, throwError } from "rxjs";
import { inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
    const matSnackBar = inject(MatSnackBar);
    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            const excluded: string[] = [];
            if (
                !excluded.length ||
                excluded
                    .map((excluded) => error.url?.includes(excluded))
                    .every((v) => v == false)
            ) {
                matSnackBar.open(
                    error.message ?? "Oops, something went wrong",
                    "Close",
                );
            }
            return throwError(() => error);
        }),
    );
};
