import { Component, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { ProfileStoreState } from "./core/store/profile";
import { Store } from "@ngrx/store";
import { ThemeService } from "./partials/services/theme.service";

@Component({
    selector: "app-root",
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
})
export class AppComponent {
    private readonly _profileStore: Store<{ profile: ProfileStoreState }> =
        inject<Store<{ profile: ProfileStoreState }>>(Store);
    private readonly _matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
    private readonly _domSanitizer: DomSanitizer = inject(DomSanitizer);
    private readonly _themeService: ThemeService = inject(ThemeService);

    constructor() {
        this._registerIcons();
        this._themeService.init();
    }

    private _registerIcons(): void {
        [
            { name: "users", url: "assets/icons/custom/users.svg" },
            { name: "download", url: "assets/icons/custom/download.svg" },
            { name: "logout", url: "assets/icons/custom/logout.svg" },
            { name: "edit", url: "assets/icons/custom/edit.svg" },
        ].forEach((item) => {
            this._matIconRegistry.addSvgIcon(
                item.name,
                this._domSanitizer.bypassSecurityTrustResourceUrl(item.url),
            );
        });
    }
}
