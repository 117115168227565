import { Routes } from "@angular/router";
import { getAdminRole, getIncidentsCases, getOfferPackagesList, getTableSettings, } from "./resolvers";
import { requestTableName, RequestTableSettings, } from "./pages/requests/requests.component";
import { userGuard } from "./partials/guards/user.guard";

export const routes: Routes = [
    {
        path: "login",
        title: "Safe My Crypto - Login",
        loadChildren: () => import("./pages/auth/auth.module"),
    },
    {
        path: "",
        loadComponent: () => import("./layout/layout.component"),
        resolve: {
            adminRole: getAdminRole
        },
        children: [
            {
                path: "logs",
                title: "Safe My Crypto - Logs",
                loadComponent: () => import('./pages/logs/logs.component').then(c => c.LogsComponent)
            },
            {
                path: "requests",
                title: "Safe My Crypto - Requests",
                resolve: {
                    initCaseIncidentResponse: getIncidentsCases,
                    loadedTableSettings:
                        getTableSettings<RequestTableSettings>(requestTableName),
                    adminRole: getAdminRole
                },
                loadComponent: () => import("./pages/requests/requests.component"),
            },
            {
                path: "case/:incidentId",
                title: "Safe My Crypto - Case",
                loadComponent: () => import("./pages/case/case.component").then(c => c.CaseComponent),
                children: [
                    {
                        path: "tabs",
                        children: [
                            {
                                path: "tab-case",
                                loadComponent: () => import("./pages/case/tabs/tab-case/tab-case.component").then(c => c.TabCaseComponent)
                            },
                            {
                                path: "tab-offer",
                                loadComponent: () => import("./pages/case/tabs/tab-offer/tab-offer.component").then(c => c.TabOfferComponent)
                            },
                            {
                                path: "tab-invoices",
                                canActivate: [userGuard(['MANAGER', 'INTEGRATION'])],
                                loadComponent: () => import("./pages/case/tabs/tab-invoices/tab-invoices.component").then(c => c.TabInvoicesComponent)
                            },
                            {
                                path: "tab-reports",
                                loadComponent: () => import("./pages/case/tabs/tab-reports/tab-reports.component").then(c => c.TabReportsComponent)
                            },
                            {
                                path: "tab-logs",
                                canActivate: [userGuard(['MANAGER', 'INTEGRATION'])],
                                loadComponent: () => import("./pages/case/tabs/tab-logs/tab-logs.component").then(c => c.TabLogsComponent)
                            },
                            {
                                path: '',
                                pathMatch: 'full',
                                redirectTo: 'tab-case'
                            }
                        ]
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'tabs/tab-case'
                    }
                ],
            },
            {
                path: "packages",
                title: "Safe My Crypto - Packages",
                resolve: {
                    initialActiveOfferPackages: getOfferPackagesList({
                        isEssential: true,
                        isDeleted: false,
                    }),
                    initialDeletedOfferPackages: getOfferPackagesList({
                        isEssential: true,
                        isDeleted: true,
                    }),
                },
                loadComponent: () => import("./pages/packages/packages.component"),
            },
            {
                path: "404",
                title: "Safe My Crypto - 404",
                loadComponent: () =>
                    import("./pages/not-found/not-found.component"),
            },
            {
                path: "something-went-wrong",
                title: "Safe My Crypto - Upsss",
                loadComponent: () =>
                    import(
                        "./pages/something-went-wrong/something-went-wrong.component"
                        ),
            },
            {
                path: "",
                title: "Safe My Crypto",
                pathMatch: "full",
                loadComponent: () =>
                    import("./pages/welcome/welcome.component"),
            },
        ],
    },
    {
        path: "**",
        redirectTo: "404",
    },
];
