import { Provider } from "@angular/core";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";

export const snackBarOptions: Provider = {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: {
        duration: 5000,
        panelClass: "break-all",
        verticalPosition: "bottom",
    },
};

export const dialogOptions: Provider = {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: { width: "600px", maxWidth: "95vw" },
};
